<template>
    <section class="modatta-landing-how-it-works modatta-timeline">
        <h2>Aproveitar o app é fácil e grátis!</h2>
        <div class="timeline-wrapper remove-scrollbar" :style="{ 'padding-left': paddingLeft }">
            <div class="timeline">
                <div class="timeline-item" v-for="(item, index) in items" :key="index">
                    <div class="image">
                        <div class="oval"></div>
                        <img :src="`/svg/landing-pages/${item.image}`" />
                    </div>
                    <div class="title">{{item.title}}</div>
                    <div class="description">{{item.description}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {
                windowWidth: window.innerWidth,

                items: [
                    {title: 'Entre no app e nos conte sobre você', description: 'Baixe o app, faça seu cadastro e complete o seu perfil. Fique tranquilo, todos os seus dados permanecem apenas em seu celular e só você tem acesso a eles, ok?', image: 'landing-how-it-works-1.svg'},
                    {title: 'Participe de missões e ganhe recompensas em dinheiro', description: 'Após completar o seu perfil, a Modatta te conecta com as marcas que são a sua cara. Lá, elas oferecerão missões para participar e ganhar recompensas em dinheiro!', image: 'landing-how-it-works-2.svg'},
                    {title: 'Ganhe ainda mais', description: 'Para ganhar ainda mais recompensas, convide amigos para baixar o app e também ao fazer parte do seu círculo de seguidores. É simples e rápido!', image: 'landing-how-it-works-3.svg'},
                    {title: 'Resgate as recompensas e transfira para sua conta!', description: 'Todas as suas recompensas estarão em sua carteira e você pode fazer o resgate com um pix para a sua conta. Sem burocracia e 100% seguro.', image: 'landing-how-it-works-4.svg'},
                ]
            }
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onResize);
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener("resize", this.onResize);
            });
        },
        computed: {
            paddingLeft() {
                const maxWidth = 1920;
                const containerWidth = 1300;
                const paddingLeft =
                    this.windowWidth > containerWidth
                    ? this.windowWidth > maxWidth
                        ? Math.round((maxWidth - containerWidth) / 2)
                        : Math.round((this.windowWidth - containerWidth) / 2)
                    : 20;
                return `${paddingLeft}px`;
            },
        },
        methods: {
            onResize() {
                this.windowWidth = window.innerWidth;
            },
        }
    }
</script>