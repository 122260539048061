<template>
    <section class="modatta-landing-how-it-works modatta-timeline">
        <a href="https://portal.modatta.com" class="button is-primary" id="landing-b2b">Experimente Gratuitamente</a>
        <h2>Como funciona?</h2>
        <div class="image-wrapper">
            <img src="/svg/landing-pages/landing-companies-how-it-works.svg">
        </div>
        <div class="timeline-wrapper remove-scrollbar" :style="{ 'padding-left': paddingLeft }">
            <div class="timeline">
                <div class="timeline-item" v-for="(item, index) in items" :key="index">
                    <div class="title">{{item.title}}</div>
                    <div class="description">{{item.description}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                windowWidth: window.innerWidth,

                items: [
                    {title: 'Crie missões para o seu público alvo', description: 'No portal você define tarefas para o seu target, dando a ele uma missão a ser cumprida.'},
                    {title: 'O seu target participa das missões no app', description: 'Quando o target realizar a tarefa da missão, você terá a sua conversão como resultado e o seu target ganhará recompensas em dinheiro'},
                    {title: 'Pague apenas quando a missões for cumprida', description: 'O seu investimento só é consumido quando a missão for realmente cumprida pelo cliente, garantido o ROI da campanha'},
                ]
            }
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onResize);
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener("resize", this.onResize);
            });
        },
        computed: {
            paddingLeft() {
                const maxWidth = 1920;
                const containerWidth = 900;
                const paddingLeft =
                    this.windowWidth > containerWidth
                    ? this.windowWidth > maxWidth
                        ? Math.round((maxWidth - containerWidth) / 2)
                        : Math.round((this.windowWidth - containerWidth) / 2)
                    : 20;
                return `${paddingLeft}px`;
            },
        },
        methods: {
            onResize() {
                this.windowWidth = window.innerWidth;
            },
        }
    }
</script>