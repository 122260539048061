<template>
  <div class="modatta-home">
    <modatta-navbar color="green"></modatta-navbar>
    <modatta-home-hero></modatta-home-hero>
    <modatta-media-logos></modatta-media-logos>
    <section class="section-1">
      <div class="modatta-container columns">
        <div class="column"></div>
        <div class="column">
            <h4 v-html="$t('individuals.section_3_subtitle')"></h4>
            <h2 v-html="$t('individuals.section_3_title')"></h2>
            <p v-html="$t('individuals.section_3_description')"></p>
            <div class="app-store-buttons">
                <a :href="googleLink" target="_blank" id="google-play-store-link">
                <div class="icon-container">
                    <img
                    src="/svg/google-play-store-icon.svg"
                    alt="Google Play Store Logo"
                    />
                </div>
                {{ $t("individuals.section_7_button_android") }}
                </a>
                <a :href="appleLink" target="_blank" id="app-store-link">
                <div class="icon-container">
                    <img
                    src="/svg/apple-app-store-icon.svg"
                    alt="Apple App Store Logo"
                    />
                </div>
                {{ $t("individuals.section_7_button_ios") }}
                </a>
            </div>
        </div>
      </div>
      <div class="floating-images-left">
        <img src="/img/section-1-img-l.png" />
      </div>
      <div class="floating-images-right">
        <img src="/img/section-1-img-r.png" />
      </div>
      <div class="floating-scribble-right">
        <img src="/svg/modatta-scribble-decoration.svg" />
      </div>
      <div class="oval"></div>
      <div class="decorations">
        <div class="background"></div>
      </div>
    </section>
    <section class="monetize-data">
      <div class="modatta-container columns">
        <div class="column">
          <h4 v-html="$t('individuals.section_4_subtitle')"></h4>
          <h2 v-html="$t('individuals.section_4_title')"></h2>
          <p v-html="$t('individuals.section_4_description')"></p>
          <div class="app-store-buttons grey-background">
            <a :href="googleLink" target="_blank" id="google-play-store-link">
              <div class="icon-container">
                <img
                  src="/svg/google-play-store-icon.svg"
                  alt="Google Play Store Logo"
                />
              </div>
              {{ $t("individuals.section_4_button_android") }}
            </a>
            <a :href="appleLink" target="_blank" id="app-store-link">
              <div class="icon-container">
                <img
                  src="/svg/apple-app-store-icon.svg"
                  alt="Apple App Store Logo"
                />
              </div>
              {{ $t("individuals.section_4_button_ios") }}
            </a>
          </div>
        </div>
        <div class="column">
          <div class="image-container">
            <img
              src="/img/section-2-phone.png"
              alt="Phone with Modatta offers"
            />
          </div>
        </div>
      </div>
    </section>
    <section
      class="floating-title"
      v-html="$t('individuals.section_5_title')"
    ></section>
    <section class="learn-from-data">
      <div class="modatta-container columns">
        <div class="column">
          <div class="image-container">
            <img
              src="/img/section-3-img.svg"
              alt="Illustration showing Modatta's data points"
            />
          </div>
        </div>
        <div class="column">
          <h4 v-html="$t('individuals.section_6_subtitle')"></h4>
          <h2 v-html="$t('individuals.section_6_title')"></h2>
          <p v-html="$t('individuals.section_6_description')"></p>
          <div class="app-store-buttons grey-background">
            <a :href="googleLink" target="_blank" id="google-play-store-link">
              <div class="icon-container">
                <img
                  src="/svg/google-play-store-icon.svg"
                  alt="Google Play Store Logo"
                />
              </div>
              {{ $t("individuals.section_6_button_android") }}
            </a>
            <a :href="appleLink" target="_blank" id="app-store-link">
              <div class="icon-container">
                <img
                  src="/svg/apple-app-store-icon.svg"
                  alt="Apple App Store Logo"
                />
              </div>
              {{ $t("individuals.section_6_button_ios") }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="data-always-on-phone">
      <div class="cloud-left">
        <img src="/svg/modatta-cloud.svg" alt="Illustration of a cloud" />
      </div>
      <div class="modatta-container columns">
        <div class="column">
          <h2 v-html="$t('individuals.section_7_title')"></h2>
          <p v-html="$t('individuals.section_7_description')"></p>
          <div class="app-store-buttons">
            <a :href="googleLink" target="_blank" id="google-play-store-link">
              <div class="icon-container">
                <img
                  src="/svg/google-play-store-icon.svg"
                  alt="Google Play Store Logo"
                />
              </div>
              {{ $t("individuals.section_7_button_android") }}
            </a>
            <a :href="appleLink" target="_blank" id="app-store-link">
              <div class="icon-container">
                <img
                  src="/svg/apple-app-store-icon.svg"
                  alt="Apple App Store Logo"
                />
              </div>
              {{ $t("individuals.section_7_button_ios") }}
            </a>
          </div>
        </div>
        <div class="column">
          <div class="image-container">
            <img
              :src="
                !isMobile
                  ? '/img/section-4-phones.png'
                  : '/img/section-4-phones-mobile.png'
              "
              alt="Phones insights"
            />
          </div>
          <div class="trees">
            <img src="/img/section-4-trees.svg" alt="Illustration of trees" />
          </div>
        </div>
      </div>
      <div class="decorations">
        <div class="green-background"></div>
      </div>
    </section>
    <modatta-individuals-call-to-action></modatta-individuals-call-to-action>
    <modatta-footer></modatta-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      windowWidth: "",
      isLearnMoreActive: false,
      appleLink: process.env.VUE_APP_APPLE_STORE,
      googleLink: process.env.VUE_APP_GOOGLE_STORE,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
  },
};
</script>