<template>
  <div class="modatta-media-logos modatta-timeline">
    <div class="modatta-container">
      <h3 v-html="$t('individuals.section_2_title')"></h3>
    </div>
    <div
      class="timeline-wrapper remove-scrollbar"
      :style="{ 'padding-left': paddingLeft }"
    >
      <div class="media-logos timeline">
        <div
          class="logo timeline-item"
          v-for="(logo, index) in mediaLogos"
          :key="index"
        >
          <img :src="logo.image" :alt="logo.name + ' logo'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      mediaLogos: [
        { image: "/img/as-seen-on/dinheiro-vivo.png" },
        { image: "/img/as-seen-on/marketeer.png" },
        { image: "/img/as-seen-on/wsa.png" },
        { image: "/img/as-seen-on/web-summit.png" },
        { image: "/img/as-seen-on/collision.png" },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    paddingLeft() {
      const maxWidth = 1920;
      const containerWidth = 1200;
      const paddingLeft =
        this.windowWidth > containerWidth
          ? this.windowWidth > maxWidth
            ? Math.round((maxWidth - containerWidth) / 2)
            : Math.round((this.windowWidth - containerWidth) / 2)
          : 20;
      return `${paddingLeft}px`;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
