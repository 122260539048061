<template>
    <div class="modatta-home modatta-landing-pages modatta-landing-companies">
        <div class="modatta-landing-hero">
            <div class="logo-wrapper">
                <router-link to="/" class="logo logo--desktop">
                    <img src="/svg/modatta-logo.svg" alt="Modatta Logo" />
                </router-link>
            </div>
            <div class="modatta-container">
                <h1>Crie <span>conexões</span> reais e recompense seu target</h1>
                <p>Mostre que a sua marca dá valor ao seu cliente e o recompense como ele realmente gosta: em dinheiro!<br>Com a Modatta você cria missões para o seu público alvo, que cumprirá as tarefas determinadas por você e, ao concluir, ele ganha dinheiro - e sua marca, resultados.</p>
                <div class="image-container">
                    <img class="desktop" src="/svg/landing-pages/landing-companies-hero-desktop.png">
                    <img class="mobile" src="/svg/landing-pages/landing-companies-hero-mobile.png">
                </div>
            </div>
        </div>
        <modatta-companies-landing-how-it-works></modatta-companies-landing-how-it-works>
        <section class="landing-section-1">
            <div class="modatta-container columns">
                <div class="column">
                    <h2>Otimize o CAC e tenha o ROI <span>sob controle</span></h2>
                    <p>Você determina o quanto vai pagar por cada missão e, dessa forma, otimiza o seu CAC. Você só paga pela missão quando houver conversão, garantindo o ROI.</p>
                    <a href="https://portal.modatta.com" class="button is-primary" id="landing-b2b">QUERO TESTAR</a>
                </div>
                <div class="column">
                    <img src="/svg/landing-pages/landing-companies-1.svg" alt="">
                </div>
            </div>
        </section>
        <section class="landing-section-2">
            <div class="modatta-container columns">
                <div class="image-wrapper">
                    <img src="/svg/landing-pages/landing-companies-2.png">
                </div>
                <div class="column is-three-fifths">
                    <img src="/svg/landing-pages/landing-companies-2.png">
                </div>
                <div class="column">
                    <h2>Inove e crie relações <span>positivas</span></h2>
                    <p>Sua marca inovando na forma de conectar campanhas com o seu público alvo. Chega de anúncios intrusivos, sem que o consumidor tenha alguma vantagem. Com a Modatta, você oferece recompensas e cria conexões reais com quem realmente importa.</p>
                    <a href="https://portal.modatta.com" class="button is-primary" id="landing-b2b">Quero testar</a>
                </div>
            </div>
        </section>
        <section class="modatta-container landing-call-to-action landing-call-to-action--grey">
            <h2>Cadastre-se gratuitamente no portal e comece agora!</h2>
            <!--<p>Entra no ecosistema e cria uma relação justa com o teu público-alvo</p>-->
            <a href="https://portal.modatta.com" class="button is-primary is-yellow" id="landing-b2b">Quero testar</a>
        </section>
        <modatta-companies-landing-offers-type></modatta-companies-landing-offers-type>
        <section class="modatta-container landing-call-to-action landing-call-to-action--blue">
            <h2>Crie conexões reais, tenha brandlovers! Tudo de forma 100% segura e em conformidade com a LGPD.</h2>
            <a href="https://portal.modatta.com" class="button is-primary is-primary--is-yellow" id="landing-b2b">Experimente Grátis</a>
        </section>
        <modatta-footer></modatta-footer>
    </div>
</template>

<script>
import ModattaHowItWorks from '../components/companies/ModattaHowItWorks.vue'
    export default {
  components: { ModattaHowItWorks },
        data() {
            return {
                appleLink: process.env.VUE_APP_APPLE_STORE,
                googleLink: process.env.VUE_APP_GOOGLE_STORE,
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>