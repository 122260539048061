<template>
  <div class="modatta-footer">
    <div class="modatta-container">
      <div class="footer-top">
        <router-link to="/" class="logo">
          <img src="/svg/modatta-logo.svg" alt="Modatta Logo" />
        </router-link>
        <div class="social-media-links">
          <a
            :key="index"
            target="_blank"
            :href="item.href"
            class="social-media-link"
            v-for="(item, index) in socialMediaLinks"
          >
            <modatta-svg :icon="item.icon" color="#585858"></modatta-svg>
          </a>
          <modatta-language-dropdown></modatta-language-dropdown>
        </div>
      </div>
      <div class="sub-footer">
        <div class="start">© {{ new Date().getFullYear() }} Maindata, Lda</div>
        <div class="center">
            <a href="mailto:meajuda@modatta.org" v-html="$t('footer.support_email')"></a>
            <a target="_blank" href="https://wa.me/5521998418887" v-html="$t('footer.support_whatsapp')"></a>
        </div>
        <div class="end">

          <router-link
            to="/legal"
            class="footer-link"
            v-html="$t('footer.tc')"
          ></router-link>
          <router-link
            to="/legal"
            class="footer-link"
            v-html="$t('footer.privacy_policy')"
          ></router-link>
          <router-link
            to="/legal"
            class="footer-link"
            v-html="$t('footer.cookies')"
          ></router-link>
        </div>
      </div>
      <div class="sub-footer-fixed">
        <a href="../../legal/Website_FICHA_PROJETO.pdf">
          <img
            src="/svg/footer-logos.svg"
            alt="Logos from Modatta's partners"
            width="385px"
            height="30px"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      socialMediaLinks: [
        { icon: "facebook", href: "https://www.facebook.com/modatta.br" },
        { icon: "instagram", href: "https://www.instagram.com/modatta.br" },
        { icon: "linkedin", href: "https://www.linkedin.com/company/modatta" },
      ],
    };
  },
};
</script>