<template>
    <div class="">
        Landing
        <router-link to="/" class="">Home</router-link>
    </div>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {

            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>