import Vue from 'vue';
import i18n from './i18n';
import Buefy from 'buefy';
import "./styles/app.scss";
import App from './App.vue';
import router from './router';
import VueAnalytics from 'vue-analytics';
import VueGtm from '@gtm-support/vue2-gtm';

const isProd = process.env.NODE_ENV === 'production';

Vue.use(Buefy);
if (isProd) {
    Vue.use(VueAnalytics, {
        id: 'UA-129702174-2',
        router,
        debug: {
            enabled: !isProd,
            sendHitTask: isProd
        }
    });
    Vue.use(VueGtm, {
        id: 'GTM-WRP7ZWH', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        debug: !isProd, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    });
}

// VUE INSTANCE
Vue.config.productionTip = false;
const files = require.context("./", true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], files(key).default));

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount("#app");
