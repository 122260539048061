<template>
  <section class="team">
    <div class="modatta-container">
      <div class="team-grid">
        <div class="intro">
          <h2 v-html="$t('about.section_4_team_title')">Our Team</h2>
          <p v-html="$t('about.section_4_team_subtitle')"></p>
        </div>
        <div class="founders">
          <div
            class="team-member"
            v-for="(member, index) in founders"
            :key="index"
          >
            <div
              class="photo"
              :style="{ 'background-image': `url(/img/team/${member.photo})` }"
            ></div>
            <div class="details">
              <div class="start">
                <div class="name">{{ member.name }}</div>
                <div class="job">{{ member.job }}<br />{{ member.job2 }}</div>
              </div>
              <div v-if="member.linkedin != null" class="end">
                <a :href="member.linkedin" target="_blank">
                  <modatta-svg icon="linkedin" color="#30D6BB"></modatta-svg>
                </a>
              </div>
              <div v-if="member.github != null" class="end">
                <a :href="member.github" target="_blank">
                  <modatta-svg icon="github" color="#30D6BB"></modatta-svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="empty"></div>
        <div class="members">
          <div class="team-member" v-for="(member, index) in team" :key="index">
            <div
              class="photo"
              :style="{ 'background-image': `url(/img/team/${member.photo})` }"
            ></div>
            <div class="details">
              <div class="start">
                <div class="name">{{ member.name }}</div>
                <div class="job">{{ member.job }}<br />{{ member.job2 }}</div>
              </div>
              <div v-if="member.linkedin != null" class="end">
                <a :href="member.linkedin" target="_blank">
                  <modatta-svg icon="linkedin" color="#30D6BB"></modatta-svg>
                </a>
              </div>
              <div v-if="member.github != null" class="end">
                <a :href="member.github" target="_blank">
                  <modatta-svg icon="github" color="#30D6BB"></modatta-svg>
                </a>
              </div>
            </div>
          </div>
          <a
            href="mailto:meajuda@modatta.org?subject=I want to join the team"
            class="team-member add-new-member"
          >
            <div class="photo">
              <modatta-svg icon="plus" color="#101010"></modatta-svg>
            </div>
            <div class="details">
              <div class="name">Be part of the team</div>
            </div>
          </a>
        </div>
        <div class="empty"></div>
        <div class="advisors">
          <div
            class="team-member"
            v-for="(member, index) in advisors"
            :key="index"
          >
            <div
              class="photo"
              :style="{ 'background-image': `url(/img/team/${member.photo})` }"
            ></div>
            <div class="details">
              <div class="start">
                <div class="name">{{ member.name }}</div>
                <div class="job">{{ member.job }}<br />{{ member.job2 }}</div>
              </div>
              <div v-if="member.linkedin != null" class="end">
                <a :href="member.linkedin" target="_blank">
                  <modatta-svg icon="linkedin" color="#30D6BB"></modatta-svg>
                </a>
              </div>
              <div v-if="member.github != null" class="end">
                <a :href="member.github" target="_blank">
                  <modatta-svg icon="github" color="#30D6BB"></modatta-svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="advisors-title">
          <div class="type" v-html="$t('about.section_4_advisor_title')"></div>
        </div>
      </div>
      <div class="team-grid-mobile">
        <div class="intro">
          <h2 v-html="$t('about.section_4_team_title')"></h2>
          <p v-html="$t('about.section_4_team_subtitle')"></p>
        </div>
        <div class="members">
          <div
            class="team-member"
            v-for="(member, index) in [...founders, ...team]"
            :key="index"
          >
            <div
              class="photo"
              :style="{ 'background-image': `url(/img/team/${member.photo})` }"
            ></div>
            <div class="details">
              <div class="start">
                <div class="name">{{ member.name }}</div>
                <div class="job">{{ member.job }}<br />{{ member.job2 }}</div>
              </div>
              <div v-if="member.linkedin != null" class="end">
                <a :href="member.linkedin" target="_blank">
                  <modatta-svg icon="linkedin" color="#30D6BB"></modatta-svg>
                </a>
              </div>
              <div v-if="member.github != null" class="end">
                <a :href="member.github" target="_blank">
                  <modatta-svg icon="github" color="#30D6BB"></modatta-svg>
                </a>
              </div>
            </div>
          </div>
          <a
            class="team-member add-new-member"
            href="mailto:meajuda@modatta.org?subject=I want to join the team"
          >
            <div class="photo">
              <modatta-svg icon="plus" color="#101010"></modatta-svg>
            </div>
            <div class="details">
              <div class="name" v-html="$t('about.section_4_team_apply')"></div>
            </div>
          </a>
        </div>
        <div class="advisors-title">
          <div class="type" v-html="$t('about.section_4_advisor_title')"></div>
        </div>
        <div class="advisors">
          <div
            class="team-member"
            v-for="(member, index) in advisors"
            :key="index"
          >
            <div
              class="photo"
              :style="{ 'background-image': `url(/img/team/${member.photo})` }"
            ></div>
            <div class="details">
              <div class="start">
                <div class="name">{{ member.name }}</div>
                <div class="job">{{ member.job }}<br />{{ member.job2 }}</div>
              </div>
              <div v-if="member.linkedin != null" class="end">
                <a :href="member.linkedin" target="_blank">
                  <modatta-svg icon="linkedin" color="#30D6BB"></modatta-svg>
                </a>
              </div>
              <div v-if="member.github != null" class="end">
                <a :href="member.github" target="_blank">
                  <modatta-svg icon="github" color="#30D6BB"></modatta-svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      founders: [
        {
          name: "Rodrigo Moretti",
          job: "Co-founder",
          job2: "CEO and CTO",
          photo: "rodrigo.png",
          linkedin: "https://www.linkedin.com/in/rodrigomoretti/",
          github: null,
        },
        {
          name: "Eduardo Pinto Basto",
          job: "Co-founder",
          job2: "COO and CMO",
          photo: "eduardo.png",
          linkedin: "https://www.linkedin.com/in/eduardopintobasto1/",
          github: null,
        },
        {
          name: "Tomás Falcão e Cunha",
          job: "Co-founder",
          job2: "Key advisor",
          photo: "tomas.png",
          linkedin:
            "https://www.linkedin.com/in/tom%C3%A1s-falc%C3%A3o-e-cunha-a54b9933/",
          github: null,
        },
      ],
      team: [
        {
          name: "Hugo Palminha",
          job: "Head of development",
          job2: "",
          photo: "hugo.png",
          linkedin: null,
          github: null,
        },
        {
          name: "Diogo Morais",
          job: "Hybrid App Development",
          job2: "",
          photo: "diogo.png",
          linkedin: null,
          github: null,
        },
        {
          name: "Renato Menino",
          job: "Digital Product Designer",
          job2: "",
          photo: "renato.png",
          linkedin: null,
          github: null,
        },
      ],
      advisors: [
        {
          name: "Qiwei Han, PhD",
          job: "Data Science and Business Analytics",
          job2: "",
          photo: "qiwei.png",
          linkedin: null,
          github: "https://qiweihan.github.io/",
        },
        // {name: 'Renato Menino', job: 'CTO & co-founder', photo: 'diogo.png', linkedin: 'https://linkedin.com'},
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
