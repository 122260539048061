<template>
    <b-dropdown aria-role="list" class="modatta-language-dropdown">
        <template #trigger="{ active }">
            <button class="modatta-button modatta-button--transparent">
                <span>{{selectedLanguage.lang_id}}</span>
                <modatta-svg :icon="active ? 'menu-up' : 'menu-down'" :color="(!color) ? '#30D6BB' : color"></modatta-svg>
            </button>
        </template>
        <b-dropdown-item 
            aria-role="listitem" 
            @click="changeLanguage(lang)"
            v-for="(lang, index) in languages" :key="index"
        >
            {{lang.name}}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    export default {
        props: ['color'],
        data() {
            return {

                languages: [
                    {name: 'Português (PT)', lang_id: 'PT', id: 'LNG1', i18n: 'pt'},
                    {name: 'Português (BR)', lang_id: 'BR', id: 'LNG5', i18n: 'br' },
                    {name: 'English', lang_id: 'EN', id: 'LNG2', i18n: 'en'},
                    //{name: 'Español', lang_id: 'ES', id: 'LNG3', i18n: 'es'},
                ],
                selectedLanguage: {name: '', lang_id: '', id: '', i18n: ''},
            }
        },
        mounted() {
            switch (this.$i18n.locale) {
                case 'pt': this.selectedLanguage = {name: 'Português (PT)', lang_id: 'PT', id: 'LNG1', i18n: 'pt'}; break;
                case 'br': this.selectedLanguage = { name: 'Português (BR)', lang_id: 'BR', id: 'LNG5', i18n: 'br' }; break;
                case 'en': this.selectedLanguage = {name: 'English', lang_id: 'EN', id: 'LNG2', i18n: 'en'}; break;
                //case 'es': this.selectedLanguage = {name: 'Español', lang_id: 'ES', id: 'LNG3', i18n: 'es'}; break;
                default: this.selectedLanguage = {name: 'English', lang_id: 'EN', id: 'LNG2', i18n: 'en'}; break;
            }
        },
        methods: {
            changeLanguage(lang) {
                console.log("will change language");
                this.selectedLanguage = lang;
                this.$i18n.locale = lang.i18n;
            } 
        }
    }
</script>