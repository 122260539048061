<template>
  <div class="modatta-featured modatta-timeline">
    <div
      class="timeline-wrapper remove-scrollbar"
      :style="{ 'padding-left': paddingLeft }"
    >
      <div class="timeline">
        <a
          :href="item.href"
          class="timeline-item"
          v-for="(item, index) in items"
          :key="index"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <div
            class="photo"
            :style="{ 'background-image': `url(/img/featured/${item.photo})` }"
          ></div>
          <div class="details">
            <div class="start">
              <div class="category" :style="{ color: getItemColor(item) }">
                {{ item.category }}
              </div>
              <div class="date">{{ item.date }}</div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,

      items: [
        {
          photo: "news-2.png",
          category: "News",
          date: "9 Jul, 2021",
          title: "Modatta is in Marketeer! Take a look.",
          href: "https://marketeer.sapo.pt/esta-app-permite-lhe-ganhar-ate-2-mil-euros-com-os-seus-dados-e-sem-arriscar-a-privacidade",
        },
        {
          photo: "news-1.png",
          category: "News",
          date: "11 Jul, 2021",
          title: "This week we are featured in Dinheiro Vivo, don't miss out!",
          href: "https://www.dinheirovivo.pt/fazedores/modatta-dados-do-telemovel-dao-dinheiro-para-utilizadores-13922050.html",
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    paddingLeft() {
      const maxWidth = 1920;
      const containerWidth = 1200;
      const paddingLeft =
        this.windowWidth > containerWidth
          ? this.windowWidth > maxWidth
            ? Math.round((maxWidth - containerWidth) / 2)
            : Math.round((this.windowWidth - containerWidth) / 2)
          : 20;
      return `${paddingLeft}px`;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    getItemColor(item) {
      switch (item.category) {
        case "Marketing":
          return "#40B2C9";
        case "Awards":
          return "#30D6BB";
        case "Funds":
          return "#F8CD60";
      }
    },
  },
};
</script>