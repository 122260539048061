<template>
  <div class="modatta-navbar">
    <div
      class="modatta-container"
      :class="{ 'navbar-item--blue': color === 'blue' }"
    >
      <div class="start">
            <router-link to="/" class="logo logo--desktop">
                <img src="/svg/modatta-logo.svg" alt="Modatta Logo" />
            </router-link>
            <router-link to="/" class="logo logo--mobile">
                <img src="/svg/modatta-icon.svg" alt="Modatta Logo" />
            </router-link>
      </div>
      <div class="center">
        <router-link
          :to="item.href"
          class="navbar-item"
          v-for="(item, index) in navItems"
          :key="index"
          :class="{ 'navbar-item--selected': isSelected === item.href }"
          >{{ $t(`header.${item.name}`) }}</router-link
        >
      </div>
      <div class="end">
        <template v-if="color === 'green'">
          <button
            class="modatta-button button modatta-button--primary"
            @click="handleClick(mainCallToAction)"
          >
             {{ $t(`header.${mainCallToAction.name}`) }}
          </button>
        </template>
        <template v-if="color === 'blue'">
          <button
            class="modatta-button button"
            @click="handleClick(signInCallToAction)"
          >
             {{ $t(`header.${signInCallToAction.name}`) }}
          </button>
          <button
            class="modatta-button button modatta-button--blue"
            @click="handleClick(mainCallToAction)"
          >
            {{ $t(`header.${mainCallToAction.name}`) }}
          </button>
          <!-- <modatta-language-dropdown color="#2355C3"></modatta-language-dropdown> -->
        </template>

        <div class="mobile-menu-trigger-wrapper">
            <button
                class="modatta-button button mobile-cta"
                @click="handleClick(mainCallToAction)"
                :class="{
                    'modatta-button--blue': color === 'blue',
                    'modatta-button--primary': color === 'green',
                }"
            >
                {{ $t(`header.${mainCallToAction.name}`) }}
            </button>
            <button
                class="mobile-menu-trigger"
                @click="isMobileMenuActive = !isMobileMenuActive"
                :class="{ open: !isMobileMenuActive, close: isMobileMenuActive }"
            >
                <span :class="{ 'is-blue': color === 'blue' }"></span>
                <span :class="{ 'is-blue': color === 'blue' }"></span>
                <span :class="{ 'is-blue': color === 'blue' }"></span>
            </button>
        </div>
      </div>
    </div>
    <div
        class="mobile-menu-wrapper"
        :class="{
            show: isMobileMenuActive,
            hide: !isMobileMenuActive,
            'navbar-item--blue': color === 'blue',
        }"
    >
      <div class="mobile-menu">
            <div class="navbar-items">
                <router-link
                    :to="item.href"
                    class="navbar-item"
                    v-for="(item, index) in navItems"
                    :key="index"
                    :class="{ 'navbar-item--selected': isSelected === item.href }"
                >
                    {{ $t(`header.${item.name}`) }}
                </router-link>
                <a
                    v-if="color === 'blue'"
                    class="navbar-item"
                    target="_blank"
                    :href="signInCallToAction.href"
                >
                    {{ $t(`header.${signInCallToAction.name}`) }}
                </a>
            </div>
            <div class="language">
            <label class="label" v-html="$t('header.menu_language')"></label>
            <modatta-language-dropdown
                :color="color === 'blue' ? '#2355C3' : '#30D6BB'"
            ></modatta-language-dropdown>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["color"],
  data() {
    return {
      isMobileMenuActive: false,
      navItems: [
        { name: "menu_individuals", href: "/" },
        { name: "menu_companies", href: "/companies" },
        { name: "menu_about", href: "/about" },
        { name: "menu_faq", href: "/faq" },
      ],
      signInCallToAction: {
        name: "menu_signin",
        href: "https://portal.modatta.com/login",
      },
      mainCallToAction: {
        name: this.color !== "blue" ? "menu_download_app" : "menu_signup",
        href:
          this.color !== "blue"
            ? this.getStoreLink()
            : "https://portal.modatta.com/signup",
        isButton: true,
      },
    };
  },
  computed: {
    isSelected() {
      return window.location.pathname;
    },
  },
  methods: {
    handleClick(item) {
      window.open(item.href, "_blank");
    },

    styleCallToActionButtons(button) {
      if (button.isButton) {
        return {
          "modatta-button--blue": this.color === "blue",
          "modatta-button--primary": this.color === "green",
        };
      }
    },

    getStoreLink() {
      const appleLink = process.env.VUE_APP_APPLE_STORE;
      const googleLink = process.env.VUE_APP_GOOGLE_STORE;
      const isIOS =
        (/Mac|iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream;
      return isIOS ? appleLink : googleLink;
    },
  },
};
</script>
