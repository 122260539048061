<template>
    <div class="modatta-home modatta-landing-pages">
        <div class="modatta-landing-hero">
            <div class="logo-wrapper">
                <router-link to="/" class="logo logo--desktop">
                    <img src="/svg/modatta-logo.svg" alt="Modatta Logo" />
                </router-link>
            </div>
            <div class="modatta-container">
                <h1 v-html="$t('landing_b2c.title')"></h1>
                <p>{{ $t('landing_b2c.subtitle') }}</p>
                <div class="app-store-buttons grey-background">
                    <a :href="googleLink" target="_blank" id="google-play-store-link">
                        <div class="icon-container">
                            <img src="/svg/google-play-store-icon.svg" alt="Google Play Store Logo"/>
                        </div>
                        {{ $t("individuals.section_4_button_android") }}
                    </a>
                    <a :href="appleLink" target="_blank" id="app-store-link">
                        <div class="icon-container">
                            <img src="/svg/apple-app-store-icon.svg" alt="Apple App Store Logo"/>
                        </div>
                        {{ $t("individuals.section_4_button_ios") }}
                    </a>
                </div>
                <div class="image-container">
                    <img class="back" src="/svg/landing-pages/landing-individuals-hero-back.svg" alt="">
                    <img class="front" src="/svg/landing-pages/landing-individuals-hero-front.png" alt="">
                </div>
            </div>
        </div>
        <modatta-landing-how-it-works></modatta-landing-how-it-works>
        <section class="landing-section-1">
            <div class="modatta-container columns">
                <div class="column">
                    <h2>Participe de missões com marcas <span>que você curte</span></h2>
                    <p>Completando o seu perfil no app, a nossa tecnologia te conecta com marcas que são a sua cara. Com elas, você participará de missões simples, fáceis e ganha recompensas em dinheiro. Seus dados, suas decisões, sua grana!</p>
                    <div class="app-store-buttons grey-background">
                        <a :href="googleLink" target="_blank" id="google-play-store-link">
                            <div class="icon-container">
                                <img src="/svg/google-play-store-icon.svg" alt="Google Play Store Logo"/>
                            </div>
                            {{ $t("individuals.section_4_button_android") }}
                        </a>
                        <a :href="appleLink" target="_blank" id="app-store-link">
                            <div class="icon-container">
                                <img src="/svg/apple-app-store-icon.svg" alt="Apple App Store Logo"/>
                            </div>
                            {{ $t("individuals.section_4_button_ios") }}
                        </a>
                    </div>
                </div>
                <div class="column">
                    <div class="oval"></div>
                    <img src="/svg/landing-pages/landing-individuals-phone-1.png" alt="">
                </div>
            </div>
        </section>
        <section class="landing-section-2">
            <div class="modatta-container columns">
                <div class="column">
                    <div class="oval"></div>
                    <img src="/svg/landing-pages/landing-individuals-phone-2.png" alt="">
                </div>
                <div class="column">
                    <h2>Aprenda o que os <span>seus dados</span> dizem sobre você</h2>
                    <p>Na Modatta, além de ser valorizado pelas suas histórias, também te contamos o que os seus dados nos dizem sobre você e o que você pode aprender com eles. Seus dados, suas decisões, sua grana!</p>
                    <div class="app-store-buttons grey-background">
                        <a :href="googleLink" target="_blank" id="google-play-store-link">
                            <div class="icon-container">
                                <img src="/svg/google-play-store-icon.svg" alt="Google Play Store Logo"/>
                            </div>
                            {{ $t("individuals.section_4_button_android") }}
                        </a>
                        <a :href="appleLink" target="_blank" id="app-store-link">
                            <div class="icon-container">
                                <img src="/svg/apple-app-store-icon.svg" alt="Apple App Store Logo"/>
                            </div>
                            {{ $t("individuals.section_4_button_ios") }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="modatta-container landing-call-to-action">
            <h2>Baixe agora, é grátis!</h2>
            <div class="app-store-buttons">
                <a :href="googleLink" target="_blank" id="google-play-store-link">
                    <div class="icon-container">
                        <img src="/svg/google-play-store-icon.svg" alt="Google Play Store Logo"/>
                    </div>
                    {{ $t("individuals.section_4_button_android") }}
                </a>
                <a :href="appleLink" target="_blank" id="app-store-link">
                    <div class="icon-container">
                        <img src="/svg/apple-app-store-icon.svg" alt="Apple App Store Logo"/>
                    </div>
                    {{ $t("individuals.section_4_button_ios") }}
                </a>
            </div>
        </section>
        <modatta-footer></modatta-footer>

        <nav class="call-to-action-footer">
            <a :href="getStoreLink" class="button is-primary" id="landing-fixed-footer">Get the App</a>
        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appleLink: process.env.VUE_APP_APPLE_STORE,
                googleLink: process.env.VUE_APP_GOOGLE_STORE,
            }
        },
        mounted() {
        },
        computed: {
            getStoreLink() {
                const appleLink = process.env.VUE_APP_APPLE_STORE;
                const googleLink = process.env.VUE_APP_GOOGLE_STORE;
                const isIOS =
                    (/Mac|iPad|iPhone|iPod/.test(navigator.platform) ||
                    (navigator.platform === "MacIntel" &&
                        navigator.maxTouchPoints > 1)) &&
                    !window.MSStream;
                return isIOS ? appleLink : googleLink;
            },
        },
        methods: {
        }
    }
</script>