<template>
  <div class="modatta-home modatta-terms modatta-faq">
    <modatta-navbar color="green"></modatta-navbar>
    <div class="decoration-right">
      <img src="/svg/modatta-scribble-decoration-flat.svg" />
    </div>
    <div class="modatta-container">
      <div class="intro">
        <h1>FAQ</h1>
        <h3>{{ $t("faq.subtitle") }}</h3>
        <p v-html="$t('faq.text_1')"></p>
      </div>

      <div class="modatta-questions">
        <div
          class="question-wrapper"
          v-for="(question, i) in questions"
          :key="i"
        >
          <div class="question" :class="{ 'is-open': question.isOpen }">
            <div
              class="question-header"
              @click="question.isOpen = !question.isOpen"
            >
              <div class="name">{{ $t(`faq.${question.question}`) }}</div>
              <div class="modatta-icon">
                <modatta-svg
                  icon="arrow-down"
                  :color="question.isOpen ? '#30D6BB' : '#101010'"
                ></modatta-svg>
              </div>
            </div>
            <div class="question-body">
              <div class="field">
                <div class="answer" v-html="$t(`faq.${question.answer}`)"></div>
                <div v-if="$te(`faq.${question.videoId}`)" class="video">
                  <vue-vimeo-player
                    ref="videoPlayer"
                    :video-id="$t(`faq.${question.videoId}`)"
                  ></vue-vimeo-player>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modatta-footer></modatta-footer>
  </div>
</template>
<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      questions: [
        {
          question: "faq_1_question",
          answer: "faq_1_answer",
          videoId: "faq_1_video_id",
          isOpen: false,
        },
        {
          question: "faq_2_question",
          answer: "faq_2_answer",
          videoId: "faq_2_video_id",
          isOpen: false,
        },
        {
          question: "faq_3_question",
          answer: "faq_3_answer",
          videoId: "faq_3_video_id",
          isOpen: false,
        },
        {
          question: "faq_4_question",
          answer: "faq_4_answer",
          videoId: "faq_4_video_id",
          isOpen: false,
        },
        {
          question: "faq_5_question",
          answer: "faq_5_answer",
          videoId: "faq_5_video_id",
          isOpen: false,
        },
        {
          question: "faq_6_question",
          answer: "faq_6_answer",
          videoId: "faq_6_video_id",
          isOpen: false,
        },
        {
          question: "faq_7_question",
          answer: "faq_7_answer",
          videoId: "faq_7_video_id",
          isOpen: false,
        },
        {
          question: "faq_8_question",
          answer: "faq_8_answer",
          videoId: "faq_8_video_id",
          isOpen: false,
        },
        {
          question: "faq_9_question",
          answer: "faq_9_answer",
          videoId: "faq_9_video_id",
          isOpen: false,
        },
        {
          question: "faq_10_question",
          answer: "faq_10_answer",
          videoId: "faq_10_video_id",
          isOpen: false,
        },
        {
          question: "faq_11_question",
          answer: "faq_11_answer",
          videoId: "faq_11_video_id",
          isOpen: false,
        },
        {
          question: "faq_12_question",
          answer: "faq_12_answer",
          videoId: "faq_12_video_id",
          isOpen: false,
        },
        {
          question: "faq_13_question",
          answer: "faq_13_answer",
          videoId: "faq_13_video_id",
          isOpen: false,
        },
        {
          question: "faq_14_question",
          answer: "faq_14_answer",
          videoId: "faq_14_video_id",
          isOpen: false,
        },
        {
          question: "faq_15_question",
          answer: "faq_15_answer",
          videoId: "faq_15_video_id",
          isOpen: false,
        },
        {
          question: "faq_16_question",
          answer: "faq_16_answer",
          videoId: "faq_16_video_id",
          isOpen: false,
        },
        {
          question: "faq_17_question",
          answer: "faq_17_answer",
          videoId: "faq_17_video_id",
          isOpen: false,
        },
        {
          question: "faq_18_question",
          answer: "faq_18_answer",
          videoId: "faq_18_video_id",
          isOpen: false,
        },
        {
          question: "faq_19_question",
          answer: "faq_19_answer",
          videoId: "faq_19_video_id",
          isOpen: false,
        },
      ],
    };
  },
};
</script>
