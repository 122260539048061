import Vue from 'vue';
import VueRouter from 'vue-router';
import Faq from '../views/Faq.vue';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Terms from '../views/Terms.vue';
import Invite from '../views/Invite.vue';
import Landing from '../views/Landing.vue';
import Companies from '../views/Companies.vue';
import LandingPageCompanies from '../views/LandingPageCompanies.vue';
import LandingPageIndividuals from '../views/LandingPageIndividuals.vue';


Vue.use(VueRouter);

const routes = [
    { path: '/', name: 'Home', component: Home, meta: { title: 'Data Influencers • Modatta' } },
    { path: '/faq', name: 'FAQ', component: Faq, meta: { title: 'FAQ • Modatta' } },
    { path: '/about', name: 'About', component: About, meta: { title: 'About • Modatta' } },
    { path: '/landing', name: 'Landing', component: Landing, meta: { title: 'Data Influencers • Modatta' } },
    { path: '/companies', name: 'Companies', component: Companies, meta: { title: 'Data Influencers • Modatta' } },
    { path: '/legal', name: 'Terms', component: Terms, meta: { title: 'Terms and Conditions • Modatta' } },
    { path: '/invite', name: 'Invite', component: Invite, meta: { title: 'Data Influencers • Modatta' } },

    { path: '/crie-conexoes-com-seu-target', name: 'LandingPageCompanies', component: LandingPageCompanies, meta: { title: 'Data Influencers • Modatta' } },
    { path: '/seus-dados-valem-dinheiro', name: 'LandingPageIndividuals', component: LandingPageIndividuals, meta: { title: 'Data Influencers • Modatta' } },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return (savedPosition) ? savedPosition : { x: 0, y: 0 };
    }
});

export default router;
