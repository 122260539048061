<template>
  <div class="modatta-individuals-call-to-action">
    <div class="oval oval--yellow"></div>
    <div class="oval oval--green"></div>
    <div class="background-image"></div>
    <h2 v-html="$t('individuals.section_8_title')"></h2>
    <div class="app-store-buttons">
      <a :href="googleLink" target="_blank" id="google-play-store-link">
        <div class="icon-container">
          <img
            src="/svg/google-play-store-icon.svg"
            alt="Google Play Store Logo"
          />
        </div>
        {{ $t("individuals.section_8_button_android") }}
      </a>
      <a :href="appleLink" target="_blank" id="app-store-link">
        <div class="icon-container">
          <img src="/svg/apple-app-store-icon.svg" alt="Apple App Store Logo" />
        </div>
        {{ $t("individuals.section_8_button_ios") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: "",
      isLearnMoreActive: false,
      appleLink: process.env.VUE_APP_APPLE_STORE,
      googleLink: process.env.VUE_APP_GOOGLE_STORE,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
  },
  props: [""],
};
</script>