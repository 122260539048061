<template>
  <div class="modatta-home-hero">
    <div class="modatta-container">
      <div class="columns">
        <div class="column is-two-thirds">
          <h3 v-html="$t('individuals.section_1_subtitle')"></h3>
          <h1 v-html="$t('individuals.section_1_title')"></h1>
          <button
            class="modatta-button button"
            @click="isLearnMoreActive = true"
          >
            <div class="icon-wrapper">
              <div class="icon-container">
                <modatta-svg icon="play" color="#FFFFFF"></modatta-svg>
              </div>
            </div>
            <div v-html="$t('individuals.section_1_video_button')"></div>
          </button>
        </div>
        <div class="column">
          <div class="image-container">
            <img
              src="/img/individuals-hero.png"
              alt="Image of a Woman Using the Modatta App"
            />
          </div>
          <div class="oval oval--large"></div>
          <div class="oval oval--small"></div>
        </div>
      </div>
    </div>
    <b-modal v-model="isLearnMoreActive">
      <div class="video-wrapper">
        <vue-vimeo-player
          ref="videoPlayer"
          video-id="359750486"
          @ready="onReady"
        ></vue-vimeo-player>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      isLearnMoreActive: false,
    };
  },
  methods: {
    onReady() {
      this.$refs.videoPlayer.play();
    },
  },
};
</script>