<template>
  <div class="modatta-invite modatta-home">
    <div class="modatta-container">
      <header>
        <router-link to="/" class="logo">
          <img src="/svg/modatta-logo.svg" alt="Modatta Logo" />
        </router-link>
        <modatta-language-dropdown></modatta-language-dropdown>
      </header>
      <div class="invitation-container">
        <h2 v-html="$t('invite.title')"></h2>
        <h1 v-html="$t('invite.subtitle')"></h1>
        <p v-html="$t('invite.text_1')"></p>
        <div class="input-wrapper">
          <vue-tel-input
            v-model="phoneNumber"
            :inputOptions="inputOptions"
            autoFormat
          ></vue-tel-input>
          <button
            class="modatta-button button is-primary"
            @click="createAccount()"
            v-html="$t('invite.button')"
          ></button>
        </div>
        <p v-html="$t('invite.text_2')"></p>
        <div class="illustration-container">
          <div class="img-container">
            <img
              src="/img/party-dog-illustration.png"
              alt="Modatta's party dog illustration"
            />
          </div>
          <div class="oval"></div>
        </div>
      </div>
      <div class="terms">
        <router-link
          to="/legal"
          class="footer-link"
          v-html="$t('footer.tc')"
        ></router-link>
        <router-link
          to="/legal"
          class="footer-link"
          v-html="$t('footer.privacy_policy')"
        ></router-link>
        <router-link
          to="/legal"
          class="footer-link"
          v-html="$t('footer.cookies')"
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  components: { VueTelInput },
  data() {
    return {
      phoneNumber: null,
      inputOptions: {
        placeholder: "93 445 36 98",
      },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    createAccount() {
      console.log("I will create an account");
      // https://ext-api.modatta.org/external/invitations&invite_ref=1234&phone_prefix=351&phone_number=912345678
    },
  },
};
</script>