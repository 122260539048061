<template>
  <div class="modatta-timeline">
    <div
      class="timeline-wrapper remove-scrollbar"
      :style="{ 'padding-left': paddingLeft }"
    >
      <div class="timeline">
        <div
          class="timeline-item"
          v-for="(item, index) in items"
          :key="index"
          :style="{ 'border-left': `5px solid ${getItemColor(item)}` }"
        >
          <div class="year" :style="{ color: getItemColor(item) }">
            {{ item.year }}
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="month">{{ item.month }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,

      items: [
        //{title: 'Modatta was founded', year: 2018, month: 'november'},
        //{title: 'Public Beta Launch', year: 2019, month: 'september'},
        //{title: 'Pitched at Websummit as an Alpha Startup', year: 2019, month: 'october'},
        //{title: 'Joined Microsoft for Startups Program', year: 2020, month: 'september'},
        //{title: '107k users - End of public bet', year: 2020, month: 'september'},
        {
          title: this.$t("about.section_3_block_1_title"),
          year: 2018,
          month: this.$t("about.section_3_block_1_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_2_title"),
          year: 2019,
          month: this.$t("about.section_3_block_2_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_3_title"),
          year: 2019,
          month: this.$t("about.section_3_block_3_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_4_title"),
          year: 2019,
          month: this.$t("about.section_3_block_4_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_5_title"),
          year: 2020,
          month: this.$t("about.section_3_block_5_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_6_title"),
          year: 2020,
          month: this.$t("about.section_3_block_6_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_7_title"),
          year: 2020,
          month: this.$t("about.section_3_block_7_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_8_title"),
          year: 2020,
          month: this.$t("about.section_3_block_8_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_9_title"),
          year: 2020,
          month: this.$t("about.section_3_block_9_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_10_title"),
          year: 2020,
          month: this.$t("about.section_3_block_10_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_11_title"),
          year: 2021,
          month: this.$t("about.section_3_block_11_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_12_title"),
          year: 2021,
          month: this.$t("about.section_3_block_12_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_13_title"),
          year: 2022,
          month: this.$t("about.section_3_block_13_subtitle"),
        } /*,
        {
          title: this.$t("about.section_3_block_14_title"),
          year: 2021,
          month: this.$t("about.section_3_block_14_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_15_title"),
          year: 2021,
          month: this.$t("about.section_3_block_15_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_16_title"),
          year: 2022,
          month: this.$t("about.section_3_block_16_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_17_title"),
          year: 2022,
          month: this.$t("about.section_3_block_17_subtitle"),
        },
        {
          title: this.$t("about.section_3_block_18_title"),
          year: 2022,
          month: this.$t("about.section_3_block_18_subtitle"),
        },*/,
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    paddingLeft() {
      const maxWidth = 1920;
      const containerWidth = 1200;
      const paddingLeft =
        this.windowWidth > containerWidth
          ? this.windowWidth > maxWidth
            ? Math.round((maxWidth - containerWidth) / 2)
            : Math.round((this.windowWidth - containerWidth) / 2)
          : 20;
      return `${paddingLeft}px`;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    getItemColor(item) {
      switch (item.year) {
        case 2018:
          return "#30D6BB";
        case 2019:
          return "#2355C3";
        case 2020:
          return "#F8CD60";
        case 2021:
          return "#30D6BB";
        case 2022:
          return "#2355C3";
      }
    },
  },
};
</script>