<template>
  <div class="modatta-container columns modatta-how-it-works">
    <div class="column is-one-third">
      <div class="items-wrapper">
        <div class="item-wrapper" v-for="(item, index) in items" :key="index">
          <div
            class="item"
            @click="selectedItemId = item.id"
            :class="{ 'item--selected': item.id === selectedItemId }"
          >
            <div
              class="icon-container"
              :style="{
                background:
                  item.id === selectedItemId
                    ? item.color.selectedFill
                    : '#F9F9F9',
              }"
            >
              <modatta-svg
                :icon="item.icon"
                :color="
                  item.id === selectedItemId
                    ? item.color.selectedFill
                    : item.color.fill
                "
                :stroke="
                  item.id === selectedItemId
                    ? item.color.selectedStroke
                    : item.color.stroke
                "
              ></modatta-svg>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
          <div v-if="item.id === selectedItemId" class="image-container-mobile">
            <img
              :src="`/svg/how-it-works/${selectedItem.image}.svg`"
              :alt="`${selectedItem.name} illustration`"
            />
          </div>
        </div>
        <div class="dashed-border-track">
          <div class="border"></div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="image-container">
        <div class="icon-container">
          <img
            :src="`/svg/how-it-works/${selectedItem.image}.svg`"
            :alt="`${selectedItem.name} illustration`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
  data() {
    return {
      selectedItemId: 1,
      items: [
        {
          id: 1,
          text: this.$t("companies.section_6_step_1"),
          icon: "briefcase",
          image: "create-a-brand",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#F0F3FE",
            selectedStroke: "#9BAFF6",
          },
        },
        {
          id: 2,
          text: this.$t("companies.section_6_step_2"),
          icon: "target",
          image: "target-audience",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#F5E9F9",
            selectedStroke: "#BB6BD9",
          },
        },
        {
          id: 3,
          text: this.$t("companies.section_6_step_3"),
          icon: "present",
          image: "define-offer",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#FDF0E4",
            selectedStroke: "#F2994A",
          },
        },
        {
          id: 4,
          text: this.$t("companies.section_6_step_4"),
          icon: "lightning",
          image: "setup-interaction",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#FDF3D7",
            selectedStroke: "#F8CD60",
          },
        },
        //{id: 5, text: 'Set up data consent', icon: 'user', image: 'create-a-brand', color: {fill: '#D7D7D7', stroke: '#ACACAC', selectedFill: '#F5E9F9', selectedStroke: '#BB6BD9'}},
        {
          id: 5,
          text: this.$t("companies.section_6_step_5"),
          icon: "wallet",
          image: "define-budget",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#E8FBF7",
            selectedStroke: "#19D2B4",
          },
        },
        {
          id: 6,
          text: this.$t("companies.section_6_step_6"),
          icon: "rocket",
          image: "launch-campaign",
          color: {
            fill: "#D7D7D7",
            stroke: "#ACACAC",
            selectedFill: "#E9EEF9",
            selectedStroke: "#2355C3",
          },
        },
      ],
    };
  },
  mounted() {},
  computed: {
    selectedItem() {
      return this.items.find((i) => i.id === this.selectedItemId);
    },
  },
  methods: {},
};
</script>