<template>
  <div class="modatta-home modatta-about">
    <modatta-about-hero></modatta-about-hero>
    <modatta-media-logos></modatta-media-logos>
    <section class="intro">
      <div class="modatta-container">
        <div class="columns">
          <div class="column">
            <p v-html="$t('about.section_2_description_left')"></p>
          </div>
          <div class="column">
            <p v-html="$t('about.section_2_description_right')"></p>
          </div>
        </div>
      </div>
    </section>
    <section class="timeline">
      <div class="modatta-container">
        <h4 v-html="$t('about.section_3_subtitle')"></h4>
        <h2 v-html="$t('about.section_3_title')"></h2>
      </div>
      <modatta-timeline></modatta-timeline>
    </section>
    <modatta-team></modatta-team>
    <section class="investors">
      <div class="background-left"></div>
      <div class="modatta-container">
        <div class="columns">
          <div class="column is-one-third">
            <h2 v-html="$t('about.section_5_title')"></h2>
            <p v-html="$t('about.section_5_description')"></p>
            <a
              href="mailto:meajuda@modatta.org?subject=I’m an investor and i want to know more"
              class="modatta-button button"
              v-html="$t('about.section_5_button_contact')"
            ></a>
          </div>
          <div class="column">
            <div class="logos-container">
              <a
                class="logo"
                target="_blank"
                :href="item.href"
                v-for="(item, index) in investors"
                :key="index"
                :style="{
                  'background-image': `url(/img/investors/${item.logo})`,
                }"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="partnerships">
      <div class="background-right"></div>
      <div class="decoration-left">
        <img src="/svg/modatta-scribble-decoration-flat.svg" />
      </div>
      <div class="modatta-container">
        <div class="columns">
          <div class="column is-one-third">
            <h2 v-html="$t('about.section_6_title')"></h2>
            <p v-html="$t('about.section_6_description')"></p>
          </div>
          <div class="column">
            <div class="logos-container">
              <a
                class="logo"
                target="_blank"
                :href="item.href"
                v-for="(item, index) in partnerships"
                :key="index"
                :style="{
                  'background-image': `url(/img/partnerships/${item.logo})`,
                }"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="featured">
      <div class="modatta-container">
        <h4 v-html="$t('about.section_7_subtitle')"></h4>
        <h2 v-html="$t('about.section_7_title')"></h2>
      </div>
      <modatta-featured></modatta-featured>
    </section>
    <section class="awards">
      <div class="modatta-container">
        <div class="columns">
          <div class="column is-one-third">
            <h2 v-html="$t('about.section_8_title')"></h2>
          </div>
          <div class="column">
            <div class="logos-container">
              <a
                class="logo"
                target="_blank"
                :href="item.href"
                v-for="(item, index) in awards"
                :key="index"
                :style="{ 'background-image': `url(/img/awards/${item.logo})` }"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modatta-footer></modatta-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      investors: [
        {
          logo: "m4v.png",
          name: "M4 Ventures",
          href: "https://m4ventures.pt/",
        },
        {
          logo: "shilling.png",
          name: "Shilling",
          href: "https://www.shilling.vc/portfolio/modatta",
        },
        {
          logo: "indico.png",
          name: "Indico",
          href: "https://www.indicocapital.com/",
        },
        {
          logo: "savvylogoblack.png",
          name: "Savvy Capital Partners",
          href: "https://savvy-capital.com/",
        },
      ],
      partnerships: [
        {
          logo: "nova.png",
          name: "Nova",
          href: "https://www2.novasbe.unl.pt/en/community/labs-hubs/entrepreneurship-hub/overview",
        },
        {
          logo: "startup-lab.png",
          name: "Startup Lab",
          href: "https://www.startuplab.pt/",
        },
        {
          logo: "microsoft.png",
          name: "Microsoft for Startups",
          href: "https://startups.microsoft.com/en-us/",
        },
        {
          logo: "edp.png",
          name: "EDP Starter",
          href: "https://www.edpstarter.com/",
        },
        {
          logo: "google.png",
          name: "Google for Startups",
          href: "https://www.indicocapital.com/accelerator-indico-google-apply",
        },
      ],
      awards: [
        {
          logo: "wsa.png",
          name: "WSA",
          href: "https://wsa-global.org/winner/modatta-money-through-data-fairly/",
        },
        {
          logo: "euro.png",
          name: "European",
          href: "https://www.modatta.com/docs/Seal_of_Excellence.pdf",
        },
        {
          logo: "ani.png",
          name: "ANI",
          href: "https://www.ani.pt/pt/financiamento/incentivos-fiscais/reconhecimento-de-idoneidade-na-pr%C3%A1tica-de-atividades-de-id/",
        },
      ],
    };
  },
};
</script>