<template>
    <div class="modatta-achievements">
        <div class="modatta-container">
            <h4>What we achieved</h4>
        </div>
    </div>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {

            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>