var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modatta-navbar"},[_c('div',{staticClass:"modatta-container",class:{ 'navbar-item--blue': _vm.color === 'blue' }},[_c('div',{staticClass:"start"},[_c('router-link',{staticClass:"logo logo--desktop",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/svg/modatta-logo.svg","alt":"Modatta Logo"}})]),_c('router-link',{staticClass:"logo logo--mobile",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/svg/modatta-icon.svg","alt":"Modatta Logo"}})])],1),_c('div',{staticClass:"center"},_vm._l((_vm.navItems),function(item,index){return _c('router-link',{key:index,staticClass:"navbar-item",class:{ 'navbar-item--selected': _vm.isSelected === item.href },attrs:{"to":item.href}},[_vm._v(_vm._s(_vm.$t(("header." + (item.name)))))])}),1),_c('div',{staticClass:"end"},[(_vm.color === 'green')?[_c('button',{staticClass:"modatta-button button modatta-button--primary",on:{"click":function($event){return _vm.handleClick(_vm.mainCallToAction)}}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (_vm.mainCallToAction.name))))+" ")])]:_vm._e(),(_vm.color === 'blue')?[_c('button',{staticClass:"modatta-button button",on:{"click":function($event){return _vm.handleClick(_vm.signInCallToAction)}}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (_vm.signInCallToAction.name))))+" ")]),_c('button',{staticClass:"modatta-button button modatta-button--blue",on:{"click":function($event){return _vm.handleClick(_vm.mainCallToAction)}}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (_vm.mainCallToAction.name))))+" ")])]:_vm._e(),_c('div',{staticClass:"mobile-menu-trigger-wrapper"},[_c('button',{staticClass:"modatta-button button mobile-cta",class:{
                  'modatta-button--blue': _vm.color === 'blue',
                  'modatta-button--primary': _vm.color === 'green',
              },on:{"click":function($event){return _vm.handleClick(_vm.mainCallToAction)}}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (_vm.mainCallToAction.name))))+" ")]),_c('button',{staticClass:"mobile-menu-trigger",class:{ open: !_vm.isMobileMenuActive, close: _vm.isMobileMenuActive },on:{"click":function($event){_vm.isMobileMenuActive = !_vm.isMobileMenuActive}}},[_c('span',{class:{ 'is-blue': _vm.color === 'blue' }}),_c('span',{class:{ 'is-blue': _vm.color === 'blue' }}),_c('span',{class:{ 'is-blue': _vm.color === 'blue' }})])])],2)]),_c('div',{staticClass:"mobile-menu-wrapper",class:{
          show: _vm.isMobileMenuActive,
          hide: !_vm.isMobileMenuActive,
          'navbar-item--blue': _vm.color === 'blue',
      }},[_c('div',{staticClass:"mobile-menu"},[_c('div',{staticClass:"navbar-items"},[_vm._l((_vm.navItems),function(item,index){return _c('router-link',{key:index,staticClass:"navbar-item",class:{ 'navbar-item--selected': _vm.isSelected === item.href },attrs:{"to":item.href}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (item.name))))+" ")])}),(_vm.color === 'blue')?_c('a',{staticClass:"navbar-item",attrs:{"target":"_blank","href":_vm.signInCallToAction.href}},[_vm._v(" "+_vm._s(_vm.$t(("header." + (_vm.signInCallToAction.name))))+" ")]):_vm._e()],2),_c('div',{staticClass:"language"},[_c('label',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm.$t('header.menu_language'))}}),_c('modatta-language-dropdown',{attrs:{"color":_vm.color === 'blue' ? '#2355C3' : '#30D6BB'}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }