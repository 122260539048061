<template>
  <div class="modatta-about-hero">
    <div class="background-left">
      <div class="decoration-left">
        <img src="/svg/modatta-scribble-decoration-flat.svg" />
      </div>
    </div>
    <div class="background-right"></div>
    <modatta-navbar color="green"></modatta-navbar>
    <div class="modatta-container">
      <h1 v-html="$t('about.section_1_title')"></h1>
      <div class="video-player">
        <div class="video-wrapper">
          <div class="video-overlay">
            <button class="play-button" @click="isPlayerPlaying = true">
              <div class="icon-wrapper">
                <div class="icon-container">
                  <modatta-svg icon="play" color="#FFFFFF"></modatta-svg>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="isPlayerPlaying">
      <div class="video-wrapper">
          <vue-vimeo-player
            ref="videoPlayer"
            video-id="543339330"
            @ready="onReady"
          ></vue-vimeo-player>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      isPlayerPlaying: false,
      isPlayerReady: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
      onReady() {
        this.$refs.videoPlayer.play();
      },
  },
};
</script>
