<template>
    <section class="modatta-landing-offers-type">
        <div class="modatta-container columns">
            <div class="column">
                <h2>Quais os tipos de missões que você pode criar?</h2>
                <p>São qualquer tipo de campanha online e offline para conectar sua marca com seus clientes, com resultados garantidos, através das missões ofertadas, para todas as etapas do funil, online e offline.</p>
                <a href="https://portal.modatta.com" class="button is-primary is-tablet" id="landing-b2b">Quero Testar</a>
            </div>
            <div class="column">
                <div class="offer-type-wrapper" v-for="(offerType, i) in offerTypes" :key="i">
                    <div class="offer-type" :class="{ 'is-open': offerType.isOpen }">
                        <div class="offer-type-header" @click="offerType.isOpen = !offerType.isOpen">
                            <div class="name-wrapper">
                                <div class="modatta-icon">
                                    <modatta-svg :icon="offerType.icon"></modatta-svg>
                                </div>
                                <div class="name">{{ offerType.name }}</div>
                            </div>
                            <div class="modatta-icon">
                                <modatta-svg icon="arrow-down" :color="offerType.isOpen ? '#2355C3' : '#101010'"></modatta-svg>
                            </div>
                        </div>
                        <div class="offer-type-body">
                            <div class="field">
                                <div class="description" v-html="offerType.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="https://portal.modatta.com" class="button is-primary is-mobile" id="landing-b2b">Registo Grátis</a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {
                offerTypes: [
                    {name: this.$t("landing_b2b.offer_type_1_name"), icon: "message", description: "O usuario recebe uma mensagem de texto dentro do app da Modatta.", isOpen: false},
                    {name: "Assistir a um vídeo", icon: "video-camera", description: "Trabalhe o awareness da sua campanha ou marca através de exibição de vídeos (que podem anteceder à pesquisas ou questionários, por exemplo)", isOpen: false},
                    {name: "Visitar um site ou página", icon: "globe", description: "Visitar um site, página ou até mesmo perfil de social", isOpen: false},
                    {name: "Executar tarefa online", icon: "wifi", description: "Desde curtir uma públicação à baixar um app ou até fazer uma compra, você escolhe!", isOpen: false},
                    {name: "Executar tarefa offline", icon: "wifi-off", description: "Visitar uma loja física, ponto de venda ou realizar uma compra física.", isOpen: false},
                    {name: "Responder pesquisa", icon: "poll", description: "Aprofunde o conhecimento sobre o seu público alvo, potencialize seus datapoints e esteja cada vez mais perto do seu brandlover.", isOpen: false},
                    //{name: "Answer survey", icon: "list", description: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim Amet minim mollit ", isOpen: false},
                ]
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>