<template>
  <div class="modatta-terms modatta-home">
    <modatta-navbar color="green"></modatta-navbar>
    <div class="decoration-right">
      <img src="/svg/modatta-scribble-decoration-flat.svg" />
    </div>
    <div class="modatta-container">
      <h1 v-html="$t('legal.section_1_title')"></h1>
      <div class="intro">
        <p v-html="$t('legal.section_1_text_1')"></p>
        <ul>
          <li v-html="$t('legal.section_1_bullet_1')"></li>
          <li v-html="$t('legal.section_1_bullet_2')"></li>
          <!--<li v-html="$t('legal.section_1_bullet_3')"></li>
          <li v-html="$t('legal.section_1_bullet_4')"></li>-->
        </ul>
        <p v-html="$t('legal.section_1_text_2')"></p>
      </div>
      <section>
        <h3 v-html="$t('legal.section_2_title')"></h3>
        <p v-html="$t('legal.section_2_text_1')"></p>

        <h4 v-html="$t('legal.section_3_title')"></h4>
        <ul>
          <li v-html="$t('legal.section_3_bullet_1')"></li>
          <li v-html="$t('legal.section_3_bullet_2')"></li>
          <li v-html="$t('legal.section_3_bullet_3')"></li>
          <li v-html="$t('legal.section_3_bullet_4')"></li>
          <li v-html="$t('legal.section_3_bullet_5')"></li>
        </ul>
        <p v-html="$t('legal.section_3_text_1')"></p>

        <h4 v-html="$t('legal.section_4_title')"></h4>
        <ul>
          <li v-html="$t('legal.section_4_bullet_1')"></li>
          <li v-html="$t('legal.section_4_bullet_2')"></li>
          <li v-html="$t('legal.section_4_bullet_3')"></li>
          <li v-html="$t('legal.section_4_bullet_4')"></li>
          <li v-html="$t('legal.section_4_bullet_5')"></li>
          <li v-html="$t('legal.section_4_bullet_6')"></li>
        </ul>

        <p v-html="$t('legal.section_4_text_1')"></p>
        <p v-html="$t('legal.section_4_text_2')"></p>
      </section>
      <section>
        <h2 v-html="$t('legal.section_5_title')"></h2>
        <h4 v-html="$t('legal.section_5_subtitle')"></h4>
        <div>
          {{ $t("legal.section_5_text_1") }}
          <a href="mailto:meajuda@modatta.org">meajuda@modatta.org</a>
        </div>
      </section>
      <div class="other-docs">
        <div class="doc" v-for="(doc, index) in documents" :key="index">
          <div class="icon-container">
            <modatta-svg :icon="doc.icon" color="#30D6BB"></modatta-svg>
          </div>
          <div class="doc-title">{{ doc.title }}</div>
          <div class="links">
            <a
              :href="`/legal/${link.href}`"
              target="_blank"
              v-for="(link, i) in doc.links"
              :key="i"
            >
              {{ link.text }}
              <span v-if="i < doc.links.length - 1">|</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <modatta-footer></modatta-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documents: [
        {
          title: this.$t("legal.file_privacy"),
          icon: "document",
          links: [
            //{ text: "EN", href: "EN_terms_conditions.pdf" },
            { text: "PT", href: "../privacy-policy.html" },
          ],
        },
        {
          title: this.$t("legal.file_tc_app"),
          icon: "smartphone",
          links: [
            //{ text: "EN", href: "EN_app_privacy_policy.pdf" },
            { text: "PT", href: "PT_Modatta_TU_do Aplicativo_280423.pdf" },
          ],
        },
       {
          title: this.$t("legal.file_tc_portal"),
          icon: "monitor",
          links: [
            //{ text: "EN", href: "EN_web_privacy_policy.pdf" },
            { text: "PT", href: "PT_Modatta_TU_do_Portal_280423.pdf" },
          ],
        },
        /*{
          title: this.$t("legal.file_cookies"),
          icon: "browser",
          links: [
            //{ text: "EN", href: "EN_cookies_policy.pdf" },
            { text: "PT", href: "PT_cookies_policy.pdf" },
          ],
        },*/
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>