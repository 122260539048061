<template>
  <div class="modatta-companies modatta-home">
    <modatta-navbar color="blue"></modatta-navbar>
    <modatta-companies-hero></modatta-companies-hero>
    <modatta-media-logos></modatta-media-logos>
    <section class="section-1">
      <div class="modatta-container columns">
        <div class="column"></div>
        <div class="column">
          <h4 v-html="$t('companies.section_3_subtitle')"></h4>
          <h2 v-html="$t('companies.section_3_title')"></h2>
          <p v-html="$t('companies.section_3_description')"></p>
          <div class="buttons-wrapper">
            <button
              class="modatta-button modatta-button--yellow"
              @click="handleClick(mainCallToAction)"
              v-html="$t('companies.section_3_button_signup')"
            ></button>
            <a
              @click="scrollTo('howitworks')"
              class="modatta-button modatta-button--transparent"
              v-html="$t('companies.section_3_button_howto')"
            ></a>
          </div>
        </div>
      </div>
      <div class="floating-images-left">
        <img src="/img/companies-section-1-img-l.png" />
      </div>
      <div class="floating-images-center">
        <img src="/img/companies-section-1-img-c.svg" />
      </div>
      <div class="floating-images-right">
        <img src="/img/companies-section-1-img-r.svg" />
      </div>
      <div class="decorations">
        <div class="background"></div>
      </div>
    </section>
    <section class="gdpr">
      <div class="modatta-container">
        <div class="image-container">
          <img src="/img/gdpr-illustration.png" alt="GDPR illustration" />
        </div>
        <h4 v-html="$t('companies.section_4_subtitle')"></h4>
        <h2 v-html="$t('companies.section_4_title')"></h2>
        <p v-html="$t('companies.section_4_description')"></p>

        <button
          class="modatta-button modatta-button--blue"
          v-html="$t('companies.section_4_button_signup')"
          @click="handleClick(mainCallToAction)"
        ></button>
      </div>
    </section>
    <section class="section-2">
      <div class="modatta-container columns">
        <div class="column">
          <div class="image-container">
            <img
              src="/img/companies-section-2-img.svg"
              alt="Modatta's Portal illustration"
            />
          </div>
        </div>
        <div class="column">
          <h4 v-html="$t('companies.section_5_subtitle')"></h4>
          <h2 v-html="$t('companies.section_5_title')"></h2>
          <p v-html="$t('companies.section_5_description')"></p>
          <div class="buttons-wrapper">
            <button
              class="modatta-button modatta-button--blue"
              @click="handleClick(mainCallToAction)"
              v-html="$t('companies.section_5_button_signup')"
            ></button>
          </div>
        </div>
      </div>
      <div class="decorations">
        <div class="background"></div>
      </div>
    </section>
    <section class="section-3" ref="howitworks">
      <div class="intro">
        <h4 v-html="$t('companies.section_6_subtitle')"></h4>
        <h2 v-html="$t('companies.section_6_title')"></h2>
      </div>
      <modatta-how-it-works></modatta-how-it-works>
      <div class="decorations">
        <div class="background"></div>
      </div>
    </section>
    <section class="section-4">
      <div class="modatta-container">
        <div class="image-container">
          <img
            :src="
              !isMobile
                ? '/img/companies-section-4-img.png'
                : '/img/companies-section-4-img-mobile.png'
            "
            alt="Modatta's Portal illustration"
          />
        </div>
        <h2 v-html="$t('companies.section_7_title')"></h2>

        <button
          class="modatta-button modatta-button--blue"
          @click="handleClick(mainCallToAction)"
          v-html="$t('companies.section_7_button_signup')"
        ></button>
      </div>
    </section>
    <modatta-footer></modatta-footer>
  </div>
</template>
<script>
import ModattaHowItWorks from "../components/companies/ModattaHowItWorks.vue";
export default {
  components: { ModattaHowItWorks },
  data() {
    return {
      windowWidth: "",
      mainCallToAction: {
        name: "try our portal",
        href: "https://portal.modatta.com/signup",
        isButton: true,
      },
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
  },
  methods: {
    handleClick(item) {
      window.open(item.href, "_blank");
    },
    scrollTo(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView();
    },
  },
};
</script>