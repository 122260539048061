var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modatta-container columns modatta-how-it-works"},[_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"items-wrapper"},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item-wrapper"},[_c('div',{staticClass:"item",class:{ 'item--selected': item.id === _vm.selectedItemId },on:{"click":function($event){_vm.selectedItemId = item.id}}},[_c('div',{staticClass:"icon-container",style:({
              background:
                item.id === _vm.selectedItemId
                  ? item.color.selectedFill
                  : '#F9F9F9',
            })},[_c('modatta-svg',{attrs:{"icon":item.icon,"color":item.id === _vm.selectedItemId
                  ? item.color.selectedFill
                  : item.color.fill,"stroke":item.id === _vm.selectedItemId
                  ? item.color.selectedStroke
                  : item.color.stroke}})],1),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])]),(item.id === _vm.selectedItemId)?_c('div',{staticClass:"image-container-mobile"},[_c('img',{attrs:{"src":("/svg/how-it-works/" + (_vm.selectedItem.image) + ".svg"),"alt":((_vm.selectedItem.name) + " illustration")}})]):_vm._e()])}),_vm._m(0)],2)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"image-container"},[_c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":("/svg/how-it-works/" + (_vm.selectedItem.image) + ".svg"),"alt":((_vm.selectedItem.name) + " illustration")}})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashed-border-track"},[_c('div',{staticClass:"border"})])}]

export { render, staticRenderFns }